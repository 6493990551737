$theme-color-2: #2a93d5;

.service {
  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.heading {
    text-align: center;
    margin: 1rem 0 0 0;
  }

  &.step-one {
    &.container {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      min-height: 500px;
    }

    &.map {
      flex-basis: 50%;
    }

    &.options {
      flex-basis: 40%;

      .location-selector-input {
        display: flex;
        min-height: min-content;
        position: relative;

        h1 {
          padding: 0;
          margin: 0;
        }

        .form {
          position: absolute;
          top: 60%;
          right: -20%;
          background-color: var(--light);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
          border-radius: 5px;
          padding: 10px 20px;
          z-index: 9999;
        }

        .line {
          min-height: 110%;
          width: 2px;
          background-color: $theme-color-2;
          position: absolute;
          top: calc(-60% + 15px);
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
          transition: all 0.2s;
          opacity: 1;
          animation: fadein 0.2s;
          user-select: none;
          pointer-events: none;
        }

        .line.active {
          opacity: 0;
        }

        .counter-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
        }

        .counter {
          z-index: 11;
          background-color: $theme-color-2;
          height: 30px;
          width: 30px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          color: var(--light);
        }

        .btns-container {
          position: absolute;
          top: 50%;
          right: -30px;
          transform: translateY(-50%);
        }

        .delete-btn,
        .options-btn {
          margin: 5px 0;
          font-size: 20px;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          border: none;
        }

        .delete-btn {
          color: var(--light);
          background-color: var(--danger);
        }

        .options-btn {
          color: var(--dark);
          background: none;
        }

        .searchbar {
          position: relative;
          width: 100%;
          margin: 1rem;

          input {
            position: relative;
          }

          .searchbar-content {
            width: 100%;
            position: absolute;
            left: 0;
            background-color: var(--light);
            padding: 5px;
            border-radius: 4px;
            display: none;
          }

          .searchbar-content.active {
            display: inline-block;
            z-index: 999999;
          }
        }
      }

      & .selector-container .minimized {
        display: none;
      }

      & .selector-container {
        .hide-btn {
          position: absolute;
          top: 1rem;
          right: 1rem;
          border: none;
          background: none;
          font-weight: bold;
          font-size: 30px;
        }

        position: relative;
        border: 1px solid #d7d7d7;
        margin: 10px 0;
        padding: 10px;
        border-radius: 3px;
        background-color: white;
        cursor: pointer;
      }

      & .inactive {
        display: none;
      }

      & .searchbar {
        margin-bottom: 3rem;

        & .details {
          padding: 10px;

          .group {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
          }

          .contact,
          .street-nums {
            display: flex;
            justify-content: space-between;

            &>* {
              flex-basis: 48%;
            }
          }
        }
      }

      & .remove-button {
        position: absolute;
        top: 0;
        right: -25px;
        border: none;
        border-radius: 50%;
        padding: 0;
        background-color: var(--red);
        height: 45px;
        width: 45px;
        font-size: 15px;
        color: white;
      }

      & .options-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }

      & .option {
        border-radius: 3px;
        flex-basis: 33%;
        border: none;
        padding: 0.3rem 1rem;
        font-size: 18px;
        background-color: transparent;

        &.active {
          color: $theme-color-2;
        }
      }

      .location-details-form {
        animation: fadein 0.2s;

        .address {
          display: flex;
          justify-content: space-between;
        }

        .title-container {
          display: flex;
          align-items: center;

          .index {
            margin-right: 5px;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $theme-color-2;
            color: var(--light);
          }
        }

        .btns {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          button {
            margin: 0 5px;
          }
        }

        .group {
          display: flex;
          flex-direction: column;

          input {
            padding: 5px;
          }
        }
      }
    }
  }

  &.step-two {

    & .cash-container,
    & .payment-container,
    & .transport-container {
      margin: 2rem 0;
      padding: 0 10%;
      display: flex;
      justify-content: center;
      align-items: center;

      & .numbers {
        margin: 0 1rem;
        font-size: 24px;
      }
    }
  }

  &.step-three {
    i {
      font-size: 30px;
    }

    .prices {
      display: flex;
      justify-content: space-between;

      &>* {
        flex-grow: 1;
      }
    }

    .prices,
    .locations {
      display: flex;
      justify-content: space-between;

      &>* {
        margin: 0 20px;
      }

      .details {
        .contact {
          display: flex;
          justify-content: center;

          &>* {
            flex-basis: 30%;
          }
        }
      }
    }

    .submit-container {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
  }
}