.service-details {
  display: flex;
  justify-content: space-between;
  min-height: min-content;

  .info,
  .map {
    flex-basis: 50%;
    padding: 2rem;
    height: 100%;

    i {
      font-size: 30px;
    }
  }
}