.datePickerContainer {  
  display: inline-block;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 20px;
  position: relative;
}
.datePickerLabel {
  margin-right: 5px;
  text-transform: uppercase;
  font-weight: 400;
  color: #8F9BB3;
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
}
.datePickerValue {
  // font-weight: 400;
  color: #222B45;
  font-size: 16px;
  border-bottom: solid;
  border-width: 1px;
  border-color: #222B45;
  width: 230px;
  display: block;
  cursor: pointer;

  i {
    cursor: pointer;
    float: right;
    font-size: 14px;
    margin-top: 4px;
    margin-right: 4px;
    color: #222B45;
  }
}

.datePicker {
  position: absolute;
  margin-top: 60px;
  z-index: 50;
}