.table {
  display: table;
  width: 100%;
  background-color: transparent;
  margin-bottom: 20px;

  li {
    display: table;
    width: 100%;
    margin: 4px 0;
    border-bottom: 1px solid #efefef;
    /* font-weight: normal; */
    /* color: #aaa; */
  }

  p {
    font-weight: inherit;
    color: inherit;
    padding-right: 5px;
    letter-spacing: .1px;
    /* text-align: right; */
    display: table-cell;
    width: 50%;
  }

  span {
    display: table-cell;
    letter-spacing: .1px
  }
}