.container {
  div {
    margin-bottom: 10px;

    p {
      font-weight: 300;
    }
  }
}

.containerImage {
  height: 70px;
  width: 70px;
}

.image {
  border-radius: 100%;
}