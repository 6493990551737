.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip-content {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0.9;
  white-space: nowrap;
}

.custom-tooltip-text {
  display: block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}