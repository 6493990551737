.notification {
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  padding: 20px 40px 30px 25px;
  background: $color-white;
  position: relative;
  margin: 10px 25px;

  &:hover {
    cursor: pointer;
  }

  &.notification--image {
    height: 106px;
    overflow: hidden;
  }

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px;

    .notification__message {
      text-align: center;
      width: calc(100% - 30px);
    }
  }

  &.notification--theme-light {
    background: $color-white;
  }

  &.notification--theme-dark {
    background: $color-black-background;
    $color-black-background: 1px solid #33333a;
    color: $color-dusty-white;
  }

  &.notification--primary {
    background: $color-blue;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--success {
    background: $color-accent;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--warning {
    background: $color-yellow;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--danger {
    background: $color-red;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }
}

.rc-notification-notice-close-x {
  color: $color-additional;
}

.notification__message {
  margin-top: 0;
  font-size: 12px;
}

.notification__title {
  margin-bottom: 8px;
}

.notification__image {
  position: absolute;
  height: 106px;
  width: 106px;
  top: 0;

  @include directify($directions) {
    #{directed('left')}: 0;
  }

  &~* {
    @include directify($directions) {
      #{directed('padding-left')}: 106px;
    }
  }
}

.sidebar.sidebar--collapse+.container__wrap {

  .notifications-tl,
  .notifications-bl {
    padding-left: 0 !important;
  }
}