$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color : white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;
$button-text-color: #fff;

$theme-color-1: #00DB90;
$theme-color-2: #0CBCE8;
$theme-color-3: #0CF2E3;
$theme-color-4: #0CF261;
$theme-color-5: #0CE81E;

// este casi no hay que usar: 
$theme-color-6: #007FD0;

$primary-color: #3a3a3a;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1 : #1f5c8d;
$gradient-color-2 : #145388;
$gradient-color-3 : #285172;

$lp-bg-color-1 : #073458;
$lp-bg-color-2 : #0a4372;
$lp-bg-color-3 : #0b60a7;
$lp-bg-color-4 : #1370bd;

$shadow-offsets-top : 1 3 10 14 19;
$shadow-blurs-top: 1.5 5 10 14 19;
$shadow-opacities-top: 0.04 0.1 0.19 0.25 0.3;

$shadow-offsets-bottom : 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.04 0.1 0.2 0.22 0.22;

// $logo-path: "/assets/logos/logo-W-repartimos.svg";
// $logo-path-mobile: "/assets/logos/logo-blanco.svg";

// $lp-logo-path: "/assets/logos/white-full.svg";
// $lp-logo-path-pinned: "/assets/logos/logo-W-repartimos.svg";
$logo-path: "";
$logo-path-mobile: "";

$lp-logo-path: "";
$lp-logo-path-pinned: "";

// @import "../main.scss";